import React from 'react'

export default ({ className, style, color }) => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1184.000000 1280.000000" className={className} style={style}>
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" style={{ fill: color }} stroke="none">
<path d="M7964 12790 c-203 -22 -389 -80 -591 -185 -372 -194 -700 -458 -924
-748 -100 -128 -295 -425 -347 -527 -34 -67 -47 -109 -62 -195 -33 -193 -70
-606 -130 -1435 -45 -621 -75 -938 -94 -985 -13 -30 -13 -30 -25 35 -21 120
-80 580 -141 1115 -99 858 -135 1113 -191 1335 -92 369 -213 592 -446 826
-263 263 -594 449 -992 557 -110 30 -125 32 -296 31 -205 0 -295 -15 -508 -85
-228 -74 -418 -182 -647 -366 -247 -198 -366 -324 -692 -733 -237 -298 -412
-535 -464 -628 -26 -46 -57 -87 -68 -92 -46 -17 -401 -301 -646 -516 -309
-270 -507 -539 -600 -814 -81 -241 -110 -468 -92 -712 21 -275 53 -409 124
-520 94 -146 309 -369 448 -464 94 -65 303 -182 429 -241 231 -108 364 -155
591 -213 384 -97 750 -148 1575 -220 539 -47 909 -94 941 -121 23 -19 -37 -76
-101 -94 -128 -37 -432 -59 -1010 -75 -937 -25 -1346 -62 -1573 -142 -316
-111 -668 -348 -890 -601 -249 -282 -367 -510 -447 -862 -48 -216 -90 -638
-84 -850 4 -111 6 -125 36 -185 76 -156 340 -510 568 -763 38 -44 151 -150
250 -237 l180 -158 90 -194 c130 -280 257 -488 454 -743 253 -330 469 -538
655 -632 153 -77 389 -139 686 -178 165 -23 552 -31 662 -16 221 32 489 166
750 373 143 114 236 213 321 341 244 365 311 596 497 1705 168 1005 197 1114
310 1172 43 22 120 37 120 24 0 -5 -11 -236 -24 -514 -14 -278 -30 -649 -37
-825 -13 -360 -6 -827 15 -1005 39 -324 148 -714 297 -1063 165 -385 304 -594
573 -862 297 -295 456 -385 822 -466 l188 -42 22 26 c23 27 34 68 61 226 20
117 10 182 -43 290 -33 66 -60 98 -238 276 -214 213 -338 358 -407 477 -108
185 -269 564 -334 788 -68 234 -127 570 -159 900 -38 392 -60 934 -46 1155 9
139 34 300 50 318 10 11 143 -411 200 -633 27 -107 70 -289 95 -405 75 -342
122 -511 167 -600 57 -110 276 -426 440 -633 253 -319 668 -676 943 -812 143
-71 239 -104 394 -135 391 -80 668 -43 984 131 164 90 237 144 307 227 36 42
99 103 141 135 41 33 96 84 122 113 104 118 227 322 341 562 168 358 417 726
641 952 80 80 125 115 222 173 121 72 122 73 152 138 17 36 60 115 96 174 36
60 77 134 90 164 132 298 218 1146 145 1430 -35 138 -182 418 -307 583 -111
148 -290 308 -460 409 -215 130 -563 253 -838 298 -345 56 -472 61 -1647 61
-1049 0 -1133 2 -1090 29 24 15 307 50 967 121 500 54 832 101 995 141 316 79
817 310 1139 526 532 358 774 670 900 1161 84 325 129 756 102 975 -28 230
-202 569 -393 768 -113 118 -205 187 -468 354 -413 262 -484 313 -621 450
-129 128 -209 228 -364 455 -315 462 -577 716 -914 884 -248 124 -542 173
-847 141z"/>
</g>
</svg>
)
