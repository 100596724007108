import React from 'react'

export default ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 361.39 54.99" className={className}>
    <path d="M76.7,8.58a9.9,9.9,0,0,0-6.93-6.94C63.67,0,39.18,0,39.18,0S14.68,0,8.57,1.64A9.9,9.9,0,0,0,1.64,8.58C0,14.8,0,27.49,0,27.49S0,40.28,1.64,46.4a9.88,9.88,0,0,0,6.93,7C14.66,55,39.18,55,39.18,55s24.49,0,30.59-1.64a9.88,9.88,0,0,0,6.93-7c1.64-6.12,1.64-18.91,1.64-18.91S78.34,14.8,76.7,8.58Z" style={{ fill: 'red' }}/>
    <path d="M293.26,44h0A3.86,3.86,0,0,1,290,46.17c-1.64,0-2.17-1.25-2.17-4.28V16.62H280V42.33c0,6.35,2.08,9.28,6.68,9.28a7.91,7.91,0,0,0,7.4-4.77h.16l.68,4.21H301V16.52h-7.75Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M260.45,21.37h0c-1.4,7.14-2.47,15.85-3,19.44H257c-.45-3.7-1.52-12.35-3-19.37L250.48,3.91H239.55V51.05h6.74V12.2l.66,3.63,6.89,35.22h6.79l6.82-35.22.72-3.65V51.05H275V3.91h-11Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M316,30.2c-2.53-1.84-4.1-3.09-4.1-5.79a2.63,2.63,0,0,1,2.15-3,2.37,2.37,0,0,1,.88,0c2.18,0,2.91,1.53,3,6.69l6.57-.28c.5-8.36-2.23-11.85-9.35-11.85-6.58,0-9.87,3-9.87,9.11,0,5.56,2.68,8.09,7.06,11.39,3.75,2.93,5.93,4.56,5.93,6.91a2.85,2.85,0,0,1-2.64,3,3.05,3.05,0,0,1-.43,0c-2.3,0-3.65-2.18-3.29-6l-6.58.12c-1,7.13,1.86,11.29,9.47,11.29,6.68,0,10.15-3.09,10.15-9.27C324.81,36.94,322,34.71,316,30.2Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M332.82,3c-2.86,0-4.19,1.06-4.19,4.76s1.33,4.77,4.19,4.77,4.21-1,4.21-4.77C337,4.26,335.74,3,332.82,3Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M361.38,38.64h0l-6.78-.35c0,6.07-.67,8-3,8S349,44.14,349,37V30.32c0-6.91.44-9.1,2.74-9.1s2.7,2.08,2.7,8.49l6.72-.46c.45-5.33-.23-9-2.3-11.07a9.49,9.49,0,0,0-7-2.23c-7.52,0-10.59,4-10.59,15.39v4.83c0,11.69,2.63,15.44,10.31,15.44a9.63,9.63,0,0,0,7-2.12C360.67,47.45,361.51,44,361.38,38.64Z" style={{ fill: '#2b2b2b' }}/>
    <rect x="329.07" y="16.62" width="7.4" height="34.43" style={{ fill: '#2b2b2b' }}/>
    <path d="M24.66,13.78h7.88L39,33.14l6.43-19.36H53.4L42.65,44H35.36Z" style={{ fill: '#fff' }}/>
    <path d="M85.76,3.91h8.47l8.11,32,8.24-32H119L105.81,51.58H98.94Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M228.61,16.16h.82V24h-.82c-5.22,0-7.5,3.16-7.5,10.32V51.54h-6.45V16.44h5.69v4.21A9.09,9.09,0,0,1,228.61,16.16Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M152.41,44.41h0a3.81,3.81,0,0,1-3.28,2.2c-1.65,0-2.17-1.25-2.17-4.27V17.07H139.2V42.78c0,6.35,2.07,9.28,6.67,9.28a7.9,7.9,0,0,0,7.4-4.77h.16l.68,4.21h6V17h-7.75Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M210.6,36.06c0-10,0-20-9.7-20-7.51,0-10.59,4-10.59,15.38v4.84c0,11.69,2.63,15.44,10.31,15.44a9.63,9.63,0,0,0,7-2.13c2.13-2,3.11-3.16,3-8.49v-1h-6.9c0,6.07-.68,6.34-3,6.34s-2.7-2.19-2.7-9.32v-1m2.75-14.77c2.2,0,2.76,2.55,2.81,9.77H198v-.67C198,23.48,198.48,21.29,200.79,21.29Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M134.85,23.45V18h-7.41V11.12h-7.27v26.8c0,9.24,3.85,13.93,11.29,13.93h3.24V45.08h-2.62c-4,0-4.64-2.34-4.64-7.5V23.45Z" style={{ fill: '#2b2b2b' }}/>
    <path d="M178.43,16c-3.2,0-5.21,1.17-6.14,3.07V3.78h-6.62V51.43h6.62V47.3c.79,2,1.92,4.15,6.15,4.15,6.64,0,8.35-6,8.35-17.76C186.79,22.47,184.36,16,178.43,16ZM176,45c-2.8,0-3.68-3.05-3.68-10.79S173.13,23,176,23s3.69,2.91,3.69,11S178.78,45,176,45Z" style={{ fill: '#2b2b2b' }}/>
  </svg>
)
