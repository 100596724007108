// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-original-index-js": () => import("./../../../src/pages/original/index.js" /* webpackChunkName: "component---src-pages-original-index-js" */),
  "component---src-pages-original-sort-created-at-js": () => import("./../../../src/pages/original/sort/created_at.js" /* webpackChunkName: "component---src-pages-original-sort-created-at-js" */),
  "component---src-pages-request-add-video-js": () => import("./../../../src/pages/request_add_video.js" /* webpackChunkName: "component---src-pages-request-add-video-js" */),
  "component---src-pages-request-add-video-preview-js": () => import("./../../../src/pages/request_add_video_preview.js" /* webpackChunkName: "component---src-pages-request-add-video-preview-js" */),
  "component---src-pages-serendipity-js": () => import("./../../../src/pages/serendipity.js" /* webpackChunkName: "component---src-pages-serendipity-js" */),
  "component---src-pages-sort-created-at-js": () => import("./../../../src/pages/sort/created_at.js" /* webpackChunkName: "component---src-pages-sort-created-at-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-music-js": () => import("./../../../src/templates/music.js" /* webpackChunkName: "component---src-templates-music-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

