module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://vtuber-music.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"vtuber-music","short_name":"vtuber-music","start_url":"/","background_color":"#db2311","theme_color":"#db2311","display":"minimal-ui","icon":"src/images/vtuber-music-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"95a86b8a1c18b1b8488a0800d8dd9ee0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-185387868-1","head":false,"anonymize":false,"respectDNT":false,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"variationId":"0","defer":true,"sampleRate":100,"siteSpeedSampleRate":10,"cookieDomain":"vtuber-music.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
